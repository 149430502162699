<template>
  <div class="page-index-wide">
    <div class="head">
      <div class="logoClass"></div>
<!--      <img class="logoClass" src="@/assets/login/logName.png" />-->
    </div>
    <el-row>
      <el-col :span="12" :offset="4">
        <el-carousel class="img">
          <el-carousel-item v-for="(item, index) in imgListL" :key="index">
            <img
              :src="item.src"
              style="width: 100%; height: 100%; border-radius: 8px 0px 8px 8px"
              alt="图片丢失了"
              :title="item.title"
            />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="5" style="background: #ffffff" class="panalClass">
        <!-- <div class="title-text"></div> -->
        <el-form
          ref="loginRef"
          :model="loginForm"
          :rules="LoginRules"
          class="login-form"
        >
          <el-form-item>
            <div class="userName">用户登录</div>
          </el-form-item>
          <el-form-item prop="accountNum">
            <el-input v-model="loginForm.accountNum" placeholder="账号：">
              <template #prefix>
                <el-icon class="el-input__icon"><user /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              autocomplete="off"
              show-password
              placeholder="密码："
            >
              <template #prefix>
                <el-icon class="el-input__icon"><unlock /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              autocomplete="off"
              size="large"
              type="text"
              v-model="loginForm.code"
              @input="handleCodeChange"
              placeholder="请输入验证码"
              @keydown.enter="submitForm('loginRef')"
            >
              <template #suffix>
                <img
                  :src="verificationCode.src"
                  class="login-code-img"
                  @click="refreshCode"
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="fPassClass">
              <!-- <el-checkbox v-model="checked2">自动登录</el-checkbox> -->
              <a style="cursor: pointer" @click="forgetPassword()">忘记密码</a>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('loginRef')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCookie } from "@/utils/cookie";
import { useRouter } from "vue-router";
import store from "@/store";
import {
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  onUnmounted,
} from "vue";
import api, { checkCode, getUserInfo, selectOneUserId } from "../api/user";
import { requestApi } from "../utils/requestApi";
import { v4 } from "uuid";
import encryptUtil from "../utils/encryptUtil";
import { ElNotification } from "element-plus";
import { METHODS } from "http";

export default {
  name: "Login",
  // components: {CodeRain},
  props: {
    msg: String,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      imgListL: [
        // {
        //   name: "汽车",
        //   src: require("@/assets/login/logo.png"),
        //   title: "这是车",
        // },
        // {
        //   name: "汽车",
        //   src: require("@/assets/login/logo1.png"),
        //   title: "这是车",
        // },
        // {
        //   name: "汽车",
        //   src: require("@/assets/login/logo2.png"),
        //   title: "这是车",
        // },
        {
          name: "风景",
          src: require("@/assets/login/logo3.png"),
          title: "这是风景",
        },
        {
          name: "风景",
          src: require("@/assets/login/logo4.png"),
          title: "这是风景",
        },
        {
          name: "风景",
          src: require("@/assets/login/logo5.png"),
          title: "这是风景",
        },
      ],
      loginForm: {
        accountNum: null,
        password: null,
      },
      verificationCode: {
        src: null,
      },
      LoginRules: {
        accountNum: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      // 验证码定时器
      codeInterval: -1,
      // UUID生成的唯一字符串
      elId: null,
      // 验证码验证是否通过
      isVerificationPassed: false,
      // 默认为平台登录界面
      isPlatform: true,
      checked2: false,
    });

    onMounted(() => {
      refreshCode();
      proxy.codeInterval = setInterval(() => {
        refreshCode();
      }, 60000);
      // 避免key重复
      // proxy.elId = v4()

      test();
    });

    onUnmounted(() => {
      clearInterval(proxy.codeInterval);
    });

    const test = () => {
      console.log(
        "我是检查海康接口",
        window.WebVideoCtrl.I_CheckPluginInstall()
      );
      let url =
        "http://localhost:8002/storeMonitoring/pictureInspection/js/codebase//transform";
      console.log(url.indexOf("store"), url.indexOf("j"));
      console.log(url.replace("/storeMonitoring/pictureInspection", ""));
    };

    // 忘记密码
    const forgetPassword = () => {
      router.push({ path: "/forgetPassword" });
    };
    // 置空表单
    const resetForm = (formName) => {
      proxy.$refs[formName].resetFields();
    };
    // 生成一个唯一的字符串，用于验证码验证
    const createUUID = () => {
      const temp = v4();
      proxy.elId = temp;
    };
    // 获取验证码
    const refreshCode = async () => {
      await createUUID();
      proxy.verificationCode.src = api.getCode + "?randomStr=" + proxy.elId;
    };
    // 验证验证码
    const handleCodeChange = async (val) => {
      proxy.isVerificationPassed = false;
      if (val.length >= 4) {
        const param = {
          code: val,
          randomStr: proxy.elId,
        };
        await requestApi(
          () => {
            return checkCode(param);
          },
          () => {
            proxy.isVerificationPassed = true;
          }
        ),
          true,
          () => {
            proxy.isVerificationPassed = false;
          };
      }
      console.log(proxy.isVerificationPassed);
    };

    const router = useRouter();

    // 登录表单校验
    const submitForm = (formName) => {
      proxy.$refs[formName].validate(async (valid) => {
        if (valid) {
          const hasToken = getCookie("AuthToken");
          if (hasToken === "") {
            // 模拟用户登录，存储token,如果是单体项目，这个应该在(重定向到在)登录页做，如果是平台项目，这里应该去cookie里获取
            const info = {
              merchantAccount: proxy.loginForm.accountNum,
              merchantPassword: encryptUtil.encrypt(proxy.loginForm.password),
            };
            if (proxy.isVerificationPassed === true) {
              try {
                await store.dispatch("user/Login", info);
                localStorage.setItem("accountNum", proxy.loginForm.accountNum);
                await router.push({ path: "/index" });
              } catch (e) {
                ElNotification({
                  title: "错误",
                  type: "error",
                  message: e.message,
                });
              }
            } else {
              ElNotification({
                // title: '错误',
                type: "warning",
                message: "请输入正确的验证码",
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };
    // 查询用户等级
    const selectUserId = () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      requestApi (
          ()=>{
            return selectOneUserId({
              userId: userInfo.platformUser.userId,
            })
          },
          (res)=>{
            localStorage.setItem("levelInfo", JSON.stringify(res.data));
          }
      )
    }

    return {
      // mockLogin,
      ...toRefs(data),
      submitForm,
      resetForm,
      refreshCode,
      createUUID,
      handleCodeChange,
      forgetPassword,
      selectUserId
    };
  },
};
</script>
<style lang="less" scoped>
a {
  color: #3773f5;
  margin-left: 66px;
}
.head {
  // margin: 24px;
}
.logoClass {
  height: 150px;
  margin-left: 24px;
}
.succClass {
  font-size: 40px;
  // font-weight: lighter;
  // font-style: oblique;
  // font-variant: small-caps;
  color: #e51934;
  opacity: 1;
}
.fPassClass {
  float: right;
  margin-right: 70px;
}
.NameClass {
  margin-left: 20px;
  font-size: 40px;
  font-weight: lighter;
  font-style: oblique;
  font-variant: small-caps;
  color: #000000;
  opacity: 1;
}
.page-index-wide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -10;
  zoom: 1;
  background-size: cover !important;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  height: 100vh;
  overflow: hidden;
  background: url("~@/assets/login/background.png") fixed no-repeat;
  .img {
    width: 50vw;
    height: 70vh;
  }
  .panalClass {
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(31, 44, 75, 0.46);
    opacity: 1;
    border-radius: 0px 8px 8px 0px;
  }
  .title-text {
    margin: 48px 62px 0px 62px;
    font-size: 35px;
    text-align: center;
    line-height: 35px;
    // font-family:  courier,"courier new", monospace;
    font-weight: lighter;
    font-style: oblique;
    font-variant: small-caps;
    color: #3773f5;
    opacity: 1;
  }
  .login-form {
    height: 100%;
    padding-top: 12%;
    z-index: 80;
    .userName {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      color: #606060;
      opacity: 1;
    }
    .el-input__icon {
      margin-left: 55px;
      color: #606060;
    }
    .login-code-img {
      width: 100px;
      height: 39px;
      background-color: #fdfdfd;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      margin-right: 49px;
      margin-top: 2px;
    }
  }
}

/deep/ .el-input__inner {
  margin: 2px 53px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(31, 44, 75, 0.46);
  opacity: 1;
  border-radius: 0px 6px 6px 0px;
  color: #575757;
}
/deep/.el-button.el-button--primary {
  width: 78%;
  margin-left: 11%;
  margin-top: 16px;
}
/deep/.el-checkbox {
  margin: 0px 66px;
}
/deep/ .el-form-item__error {
  left: 12%;
}
//密码的眼
/deep/.el-icon.el-input__icon.el-input__clear {
  margin-right: 54px;
}
/deep/.el-carousel__container {
  width: 50vw;
  height: 70vh;
}
</style>
